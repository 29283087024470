@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-cyrillic-ext-400-normal.4824ef68.woff2") format("woff2"), url("roboto-slab-cyrillic-ext-400-normal.6a47d821.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-cyrillic-400-normal.bc626962.woff2") format("woff2"), url("roboto-slab-cyrillic-400-normal.1b757121.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-greek-ext-400-normal.d07291e7.woff2") format("woff2"), url("roboto-slab-greek-ext-400-normal.8df3a622.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-greek-400-normal.28732a57.woff2") format("woff2"), url("roboto-slab-greek-400-normal.f3ef62be.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-vietnamese-400-normal.7d07d02c.woff2") format("woff2"), url("roboto-slab-vietnamese-400-normal.e577551a.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-latin-ext-400-normal.ed629057.woff2") format("woff2"), url("roboto-slab-latin-ext-400-normal.388c7e34.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-latin-400-normal.64d9f4c7.woff2") format("woff2"), url("roboto-slab-latin-400-normal.7b2aa6f0.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: More Perfect DOS VGA;
  src: url("More Perfect DOS VGA.66166114.woff2") format("woff2"), url("More Perfect DOS VGA.6fa8fb2d.woff") format("woff"), url("More Perfect DOS VGA.1b330491.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*# sourceMappingURL=index.535e86c2.css.map */
